import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  useRealm,
  Form,
  FormattedRichMessage,
  FormTextInput,
  validPhoneOrEmail,
  ContentPage,
  useCIAMContextParam,
  CoBrandingButton,
  customStyles,
  CIAMLink,
} from 'ciam-self-service-shared';
import useGoToUrl from '../../../hooks/useGoToUrl';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';
import AnalyticsDataLayer from '../../common/Analytics';
import { FU_PROMPT_PAGE_NAME } from '../constants';
import InternetExplorerUserWarning from '../../common/InternetExplorerUserWarning';

const ForgotUsernamePromptPage = (props) => {
  const { onRequestUsername } = props;
  const intl = useIntl();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const goto = useGoToUrl();
  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');

  const handleInputValueChanged = (value) => {
    setPhoneOrEmail(value);
    setFormattedPhoneNumber('');

    if (!value.includes('@')) {
      // TODO: Fix this when we implement E.164 standard for phone number
      let trimmedValue = value.replace(/\D/g, '');
      if (trimmedValue.length === 10) {
        trimmedValue = `+1 ${trimmedValue}`;
        setFormattedPhoneNumber(trimmedValue);
      } else if (trimmedValue.length === 11) {
        trimmedValue = `+${trimmedValue.charAt(0)} ${trimmedValue.substring(1)}`;
        setFormattedPhoneNumber(trimmedValue);
      } else {
        // nothing to do
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      realm,
      brand,
      goto,
      email: null,
      telephoneNumber: null,
      lang: intl.locale,
    };
    if (formattedPhoneNumber) {
      payload.telephoneNumber = formattedPhoneNumber;
      onRequestUsername(payload);
    } else {
      payload.email = phoneOrEmail;
      onRequestUsername(payload);
    }
  };

  return (
    <Page title={intl.formatMessage({ id: 'forgotUsername.prompt.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      <AnalyticsDataLayer pageName={FU_PROMPT_PAGE_NAME} />
      <ContentPage heading={<FormattedMessage id="forgotUsername.prompt.page.heading" />}>
        <InternetExplorerUserWarning />
        <FormattedRichMessage id="forgotUsername.prompt.page.content" />
        <Form onSubmit={handleSubmit}>
          <FormElement>
            <FormTextInput
              id="phoneOrEmail"
              name="phoneOrEmail"
              inputMode="email"
              label={intl.formatMessage({ id: 'forgotUsername.prompt.phoneOrEmailLabel' })}
              value={phoneOrEmail}
              onChange={handleInputValueChanged}
              required
              ariaRequired
              formattedValue={formattedPhoneNumber}
              validations={validPhoneOrEmail}
              autoFocus
              resetValidationMsgOnChange
            />
            <CIAMLink to="/forgotpassword">{intl.formatMessage({ id: 'shared.password.forgotPassword' })}</CIAMLink>
          </FormElement>
          <FormElement>
            <CoBrandingButton type="submit" customStyle={customStyles.button}>
              <FormattedMessage id="forgotUsername.prompt.submitLabel" />
            </CoBrandingButton>
          </FormElement>
        </Form>
      </ContentPage>
    </Page>
  );
};

ForgotUsernamePromptPage.propTypes = {
  onRequestUsername: PropTypes.func.isRequired,
};

export default ForgotUsernamePromptPage;

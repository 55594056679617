import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_VARIANT } from '@manulife/mux';
import { afterThreeTries } from 'components/VerifyPassword/actions';
import { updateLoginLoadingStatus, updateErrorCode } from 'components/Login/actions';
import {
  FormattedRichMessage,
  useRichIntl,
  useRealm,
  useCIAMHistory,
  usePathBuilder,
  useQueryParams,
  useCIAMContextParam,
  QUERY_PARAMS,
  ContentPage,
} from 'ciam-self-service-shared';
import initAsyncAction from 'utils/loginAuthnTree/initAsyncActionImpl';
import signinAsyncAction from 'utils/loginAuthnTree/signinAsyncActionImpl';
import { Redirect } from 'react-router';
import { mapErrorFromJson } from 'utils/error-map';
import useIsInboundSSO from '../../../hooks/useIsInboundSSO';
import Page, { AUTH_TYPE } from '../../common/Page';
import SignInForm from './SignInForm';
import { AUTH_TREE_REALM_MAPPING } from '../constants';
import { SIGN_OUT_REASONS } from '../../../utils/constants';
import useGoToUrl from '../../../hooks/useGoToUrl';
import useUniversalLink from '../../../hooks/useUniversalLink';
import SignInProductInfo from './SignInProductInfo';
import { MainContainer, SignInWrapper, SignInProductInfoWrapper } from './styledComponents';
import useOnDemandOtpRedirect from '../../OnDemandOTP/useOnDemandOtpRedirect';
import { useLocation } from 'react-router-dom';

const SIGN_OUT_REASONS_MAP = {
  [SIGN_OUT_REASONS.SIGNED_OUT]: {
    message: 'logout.success.message',
    variant: MESSAGE_VARIANT.PRIMARY_SUCCESS,
  },
  [SIGN_OUT_REASONS.INACTIVE]: {
    message: 'session.inactiveLogoutReason',
    variant: MESSAGE_VARIANT.PRIMARY_INFORMATION,
  },
  [SIGN_OUT_REASONS.MAX_SESSION]: {
    message: 'session.maxSessionLogoutReason',
    variant: MESSAGE_VARIANT.PRIMARY_INFORMATION,
  },
};

function SignInPage() {
  const realm = useRealm();
  const stage = useSelector((state) => state.loginInfo.stage);
  const loginError = useSelector((state) => state.loginInfo.loginError);
  const history = useCIAMHistory();
  const dispatch = useDispatch();
  const loginLoadingStatus = useSelector((state) => state.loginInfo.loginLoadingStatus);
  const gotoURI = useGoToUrl();
  const universalLink = useUniversalLink();
  const richIntl = useRichIntl();
  const pathBuilder = usePathBuilder();
  const brand = useCIAMContextParam('brand');
  const { locale } = richIntl;
  const { [QUERY_PARAMS.SIGN_OUT_MSG]: signOutReason } = useQueryParams();
  const isLoggedOut = Boolean(signOutReason);
  const signOutMessage = SIGN_OUT_REASONS_MAP[signOutReason] || SIGN_OUT_REASONS_MAP[SIGN_OUT_REASONS.SIGNED_OUT];
  const mainBackButtonFlag = sessionStorage.getItem('ciam.backButton-otp');
  const addNumberBackButtonFlag = sessionStorage.getItem('ciam.addNumberbackButton-otp');
  const isInboundSSO = useIsInboundSSO();
  const prevRealm = useRef(realm);
  const location = useLocation();
  const isVerified = location?.state?.isVerified;

  const handleLogin = async (username, password) => {
    try {
      await signinAsyncAction(dispatch, {
        stage,
        username,
        password,
        history,
        gotoURI,
        locale,
        realm,
        brand,
      });
    } catch (err) {
      dispatch(updateErrorCode(mapErrorFromJson('D_705')));
    } finally {
      dispatch(updateLoginLoadingStatus(false));
    }
  };

  const isOnDemand = useOnDemandOtpRedirect();

  useEffect(() => {
    if (!mainBackButtonFlag && !addNumberBackButtonFlag && !isOnDemand) {
      initAsyncAction(dispatch, AUTH_TREE_REALM_MAPPING[realm], realm, brand);
      dispatch(afterThreeTries());
    }
    if (prevRealm.current !== realm) {
      prevRealm.current = realm;
      dispatch(updateErrorCode(null));
    }
  }, [mainBackButtonFlag, addNumberBackButtonFlag, realm, brand, dispatch, isOnDemand]);

  if (universalLink) {
    window.location.href = universalLink;
    return null;
  }

  if (mainBackButtonFlag) {
    return <Redirect to={pathBuilder('/otp')} />;
  }
  if (addNumberBackButtonFlag) {
    return <Redirect to={pathBuilder('/addnumbermfa')} />;
  }

  if (isOnDemand) {
    return null;
  }
  const headingID = isVerified ? 'signin.page.firstLogin.heading' : 'signin.page.heading';

  return (
    <Page
      title={richIntl.formatMessage({ id: 'signin.page.title' })}
      heading={richIntl.formatMessage({ id: 'signin.page.heading' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
    >
      <ContentPage
        isLoggedOut={isLoggedOut}
        signoutMessage={<FormattedRichMessage id={signOutMessage.message} />}
        signoutMessageVariant={signOutMessage.variant}
        heading={<FormattedRichMessage id={headingID} />}
        contentColumns={9}
      >
        <MainContainer>
          <SignInWrapper>
            <SignInForm key={realm} onLogin={handleLogin} loginLoadingStatus={loginLoadingStatus} error={loginError} />
          </SignInWrapper>
          {!isInboundSSO && (
            <SignInProductInfoWrapper>
              <SignInProductInfo />
            </SignInProductInfoWrapper>
          )}
        </MainContainer>
      </ContentPage>
    </Page>
  );
}

export default SignInPage;
